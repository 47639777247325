@charset "UTF-8";
body {
  font-size: 16px;
  min-width: 320px;
  position: relative;
  line-height: 1.65;
  font-family: RobotoBold;
  overflow-x: hidden;
  color: #333; }
  body input,
  body textarea {
    border: #666 1px solid;
    outline: none; }
  body input:focus:required:invalid,
  body textarea:focus:required:invalid {
    border-color: red; }
  body input:required:valid,
  body textarea:required:valid {
    border-color: #333; }

a::selection {
  outline: none;
  /* Убираем границу вокруг ссылок  */ }

.text-dark {
  color: #000; }

.text-white {
  color: #fff; }

.text-red {
  color: #ff0000; }

.post-info span {
  color: #000000; }

.post-info span h3 {
  color: #ff0000; }

section {
  color: #ffffff; }

.flex-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 700px;
  height: 100vh; }

.element-1 {
  color: #ffffff; }

.boxShadowDown .menu-area ul li a {
  color: #ffffff;
  text-decoration: none; }

.element-3 {
  align-self: center;
  width: 100%; }

.header-contacts {
  height: 50px; }

section .d-header {
  min-height: 700px;
  height: 100vh;
  background-color: #000000;
  color: #ffffff; }

section,
.d-header {
  position: relative;
  background-size: cover;
  background-position: center; }

.header .site-header .top-line {
  padding-top: 40px;
  position: absolute;
  width: 100%;
  z-index: 1; }

.header-flex {
  display: flex;
  height: 100%;
  position: relative;
  top: 0; }

.flex-center {
  margin: auto; }

.header-composition {
  text-align: center;
  position: relative; }
  .header-composition p {
    margin-top: 40px;
    text-transform: none; }
  .header-composition .button {
    margin-top: 25px; }

.welcome p {
  font-style: 14px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  white-space: nowrap;
  margin: 0;
  margin-bottom: -10px; }
  .welcome p span {
    margin: 0 10px; }
  .welcome p::before, .welcome p::after {
    content: "";
    height: 1px;
    width: 100%;
    position: relative;
    background: #fff;
    display: inline-block; }

.welcome .h1 {
  font-weight: 100;
  font-size: 70px;
  margin: 0;
  line-height: 1.6;
  border-bottom: 1px solid #fff;
  white-space: nowrap; }

.welcome .h2 {
  font-weight: 100;
  font-size: 40px;
  margin: 0;
  line-height: 1.6;
  border-bottom: 1px solid #fff;
  white-space: nowrap; }

.welcome strong {
  display: block;
  text-transform: uppercase;
  font-size: 17px;
  margin-top: 15px; }
  .welcome strong .fa {
    margin-right: 10px;
    font-size: 12px;
    color: #fff9d0; }
    .welcome strong .fa:last-child {
      margin-left: 10px;
      margin-right: inherit; }

.headings {
  color: #000000;
  font-size: 30px;
  font-weight: 400;
  letter-spacing: 1px;
  line-height: 1;
  text-transform: uppercase;
  font-family: Georgia, "Times New Roman", Times, serif; }

.headings_off {
  color: #000000;
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 1px;
  line-height: 1;
  text-transform: uppercase;
  font-family: Georgia, "Times New Roman", Times, serif; }

.bg-opacity-img-1 {
  background: url(../img/bg/7.jpg);
  background-size: cover;
  background-color: #cccccc;
  /* Used if the image is unavailable */
  height: 400px;
  /* You must set a specified height */
  background-position: center;
  /* Center the image */
  background-repeat: no-repeat;
  /* Do not repeat the image */
  background-size: cover;
  /* Resize the background image to cover the entire container */ }

.breadcrumb-banner-area {
  background: url(../img/bg/4.jpg);
  background-size: cover; }

.portfolio-area-title a:hover {
  color: #ffffff;
  font-size: 42px; }

.boxShadow {
  box-shadow: 0px -4px 100px -37px #000000; }

.boxShadowDown {
  box-shadow: 0px 29px 42px -42px #000000; }

.boxShadowlight {
  box-shadow: 0px -1px 8px -4px #000000; }

.banerSb {
  position: absolute;
  top: 75px;
  right: 0px;
  width: 20%; }
